
  import {isHttpUrl} from '@/helper/ImageHelper';

  export default {
    components: {
      RegistrationOrLoginDialog: () =>
        import('@/components/candidate/RegistrationOrLoginDialog'),
    },
    props: {
      content: {
        type: Object,
        required: true,
      },
      route: {
        type: String,
        required: false,
        default: '',
      },
    },
    methods: {
      imageUrl(url) {
        return isHttpUrl(url) ? url : require(`@/assets/${url}`);
      },
    },
  };
